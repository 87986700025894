<template>
  <div>
    <b-overlay :show="isFetchingCmsSite">
      <b-card class="mb-4">
        <CmsLinkDemo />
        <b-alert
          variant="warning"
          show
        >
          Update/Re-position is not yet available. Use remove and recreate
          instead. 🥲
        </b-alert>
        <b-row v-if="cmsForm && cmsForm.isDevelopment">
          <b-col class="text-right">
            <b-overlay :show="isMerging">
              <b-button
                variant="success"
                @click="onGoProd"
              >
                Merge change to Production 🚀
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
        <validation-observer ref="cmsFormRef">
          <form @submit.prevent="onSubmit">
            <b-row>
              <b-col lg="6">
                <h5 class="text-primary">
                  Site detail
                </h5>
                <validation-provider
                  v-slot="{ errors }"
                  name="site_name"
                  rules="required"
                >
                  <b-form-group label="Site name">
                    <b-form-input
                      id="site_name"
                      v-model="cmsForm.siteName"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <b-form-group label="Site Logo">
                  <CmsFileSelect
                    id="site_logo"
                    :value="cmsForm.logoSrc"
                    @update="(value) => (cmsForm.logoSrc = value || '')"
                  />
                </b-form-group>
                <b-form-group label="Site background">
                  <CmsFileSelect
                    id="site_background"
                    :value="cmsForm.backgroundSrc"
                    @update="(value) => (cmsForm.backgroundSrc = value || '')"
                  />
                </b-form-group>
                <b-form-group label="Dashboard banner">
                  <CmsFileSelect
                    id="dashboard_banner"
                    :value="cmsForm.dashboardBannerSrc"
                    @update="
                      (value) => (cmsForm.dashboardBannerSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Nav background">
                  <CmsFileSelect
                    id="nav_background"
                    :value="cmsForm.navBackgroundSrc"
                    @update="
                      (value) => (cmsForm.navBackgroundSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Floating menu">
                  <CmsFileSelect
                    :value="cmsForm.floatingMenuBackgroundSrc"
                    @update="
                      (value) =>
                        (cmsForm.floatingMenuBackgroundSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Topmenu Background">
                  <CmsFileSelect
                    :value="cmsForm.topMenuBackgroundSrc"
                    @update="
                      (value) => (cmsForm.topMenuBackgroundSrc = value || '')
                    "
                  />
                </b-form-group>
                <h5 class="text-primary">
                  Titles
                </h5>
                <b-form-group label="Article page title">
                  <CmsFileSelect
                    :value="cmsForm.articlePageTitleSrc"
                    @update="
                      (value) => (cmsForm.articlePageTitleSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Promotion page title">
                  <CmsFileSelect
                    :value="cmsForm.promotionPageTitleSrc"
                    @update="
                      (value) => (cmsForm.promotionPageTitleSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Referral page title">
                  <CmsFileSelect
                    :value="cmsForm.referralPageTitleSrc"
                    @update="
                      (value) => (cmsForm.referralPageTitleSrc = value || '')
                    "
                  />
                </b-form-group>

                <h5 class="text-primary">
                  Buttons
                </h5>
                <b-form-group label="Topmenu login button">
                  <CmsFileSelect
                    :value="cmsForm.topMenuLoginButtonSrc"
                    @update="
                      (value) => (cmsForm.topMenuLoginButtonSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Topmenu logout button">
                  <CmsFileSelect
                    :value="cmsForm.topMenuLogoutButtonSrc"
                    @update="
                      (value) => (cmsForm.topMenuLogoutButtonSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Topmenu member button">
                  <CmsFileSelect
                    :value="cmsForm.topMenuMemberButtonSrc"
                    @update="
                      (value) => (cmsForm.topMenuMemberButtonSrc = value || '')
                    "
                  />
                </b-form-group>
                <b-form-group label="Topmenu register button">
                  <CmsFileSelect
                    :value="cmsForm.topMenuRegisterButtonSrc"
                    @update="
                      (value) =>
                        (cmsForm.topMenuRegisterButtonSrc = value || '')
                    "
                  />
                </b-form-group>

                <!-- Meta settings -->
                <h5 class="mt-3">
                  Meta Settings
                </h5>
                <validation-provider
                  v-slot="{ errors }"
                  name="meta_title"
                  rules="required"
                >
                  <b-form-group label="Meta title">
                    <b-form-input
                      id="meta_title"
                      v-model="cmsForm.metaTitle"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="meta_description"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-form-group label="Meta description">
                    <b-form-input
                      id="meta_description"
                      v-model="cmsForm.metaDescription"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="meta_url"
                  rules="required"
                >
                  <b-form-group label="Meta URL">
                    <b-form-input
                      id="meta_url"
                      v-model="cmsForm.metaUrl"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="meta_keywords"
                  rules="required"
                >
                  <b-form-group label="Meta keywords">
                    <b-form-tags
                      id="meta_keywords"
                      v-model="cmsForm.metaKeyword"
                      :state="errors[0] ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
                <b-form-group label="Meta icon ( Favicon )">
                  <CmsFileSelect
                    :value="cmsForm.metaIconSrc"
                    @update="(value) => (cmsForm.metaIconSrc = value || '')"
                  />
                </b-form-group>
                <b-form-group label="Meta image">
                  <CmsFileSelect
                    :value="cmsForm.metaImageSrc"
                    @update="(value) => (cmsForm.metaImageSrc = value || '')"
                  />
                </b-form-group>

                <h5 class="text-primary">
                  Static looks and feels (Global)
                </h5>
                <div v-if="cmsEnumTypes">
                  <b-form-group label="Top menu type">
                    <b-form-select v-model="cmsForm.topMenuType">
                      <b-form-select-option
                        v-for="(item, index) of cmsEnumTypes.topMenus"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Nav type">
                    <b-form-select v-model="cmsForm.navType">
                      <b-form-select-option
                        v-for="(item, index) of cmsEnumTypes.navs"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Floating menu type">
                    <b-form-select v-model="cmsForm.floatingMenuType">
                      <b-form-select-option
                        v-for="(item, index) of cmsEnumTypes.floatingMenus"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Footer type">
                    <b-form-select v-model="cmsForm.footerType">
                      <b-form-select-option
                        v-for="(item, index) of cmsEnumTypes.footers"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Banner type">
                    <b-form-select v-model="cmsForm.bannerType">
                      <b-form-select-option
                        v-for="(item, index) of cmsEnumTypes.banners"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Dashboard type">
                    <b-form-select v-model="cmsForm.dashboardType">
                      <b-form-select-option
                        v-for="(item, index) of cmsEnumTypes.dashboards"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                md="6"
                cols="12"
              >
                <h5 class="text-primary">
                  File Manager
                </h5>
                <CmsFileManager />
              </b-col>
            </b-row>

            <div class="text-right">
              <b-button
                variant="light"
                class="mr-2"
                @click="onCancel"
              >
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-overlay
                :show="isUpdatingCmsSite"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  v-if="cmsSite && !cmsSite.isDevelopment"
                  variant="warning"
                  @click="onEnableDevelopment"
                >
                  Enable develop
                </b-button>
                <b-button
                  v-else
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </validation-observer>
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Homepage layout
        </h5>
        <CmsLayout />
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Floating menus
        </h5>
        <CmsFloatingMenu />
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Banners
        </h5>
        <CmsBanner />
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Promotions
        </h5>
        <CmsPromotion />
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Referrals
        </h5>
        <CmsReferral />
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Articles
        </h5>
        <CmsArticle />
      </b-card>
      <b-card class="mb-4">
        <h5 class="text-primary">
          Floating Ads
        </h5>
        <CmsFloatingAds />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required } from '@validations'

export default {
  components: {
    CmsFileManager: () => import('././components/file-manager.vue'),
    CmsFileSelect: () => import('./components/file-select.vue'),

    CmsLayout: () => import('./components/layout'),
    CmsBanner: () => import('./components/banner'),
    CmsPromotion: () => import('./components/promotion'),
    CmsReferral: () => import('./components/referral'),
    CmsFloatingMenu: () => import('./components/floating-menu'),
    CmsLinkDemo: () => import('./components/link-demo.vue'),
    CmsArticle: () => import('./components/article'),
    CmsFloatingAds: () => import('./components/floating-ads'),
  },
  data() {
    return {
      required,

      siteId: '',
      masterSiteId: null,
      cmsForm: {
        logoSrc: '',
        backgroundSrc: '',
        dashboardBannerSrc: '',
        topMenuBackgroundSrc: '',
        navBackgroundSrc: '',
        articlePageTitleSrc: '',
        promotionPageTitleSrc: '',
        referralPageTitleSrc: '',
        topMenuLoginButtonSrc: '',
        topMenuLogoutButtonSrc: '',
        topMenuMemberButtonSrc: '',
        topMenuRegisterButtonSrc: '',
        floatingMenuBackgroundSrc: '',
      },
      cmsDefaultForm: {
        logoSrc: '',
        backgroundSrc: '',
        dashboardBannerSrc: '',
        topMenuBackgroundSrc: '',
        navBackgroundSrc: '',
        articlePageTitleSrc: '',
        promotionPageTitleSrc: '',
        referralPageTitleSrc: '',
        topMenuLoginButtonSrc: '',
        topMenuLogoutButtonSrc: '',
        topMenuMemberButtonSrc: '',
        topMenuRegisterButtonSrc: '',
        floatingMenuBackgroundSrc: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
      cmsSite: (state) => state.cms.cmsSite,
      isUpdatingCmsSite: (state) => state.cms.isUpdatingCmsSite,
      isFetchingEnumTypes: (state) => state.cms.isFetchingEnumTypes,
      cmsEnumTypes: (state) => state.cms.cmsEnumTypes,
      isFetchingCmsSiteFiles: (state) => state.cms.isFetchingCmsSiteFiles,
      cmsSiteFiles: (state) => state.cms.cmsSiteFiles,
      isMerging: (state) => state.cms.isMerging,
      isMerged: (state) => state.cms.isMerged,
    }),
  },
  watch: {
    cmsSite(val) {
      if (val) {
        this.setData(val)
        if (val.isDevelopment) {
          this.masterSiteId = val.cmsSiteMasterId
        }
      }
    },
    isMerged(val) {
      if (val) {
        this.$router.back()
      }
    },
  },
  async created() {
    await this.fetchEnumTypes()
    const { id } = this.$route.params
    if (id) {
      this.siteId = id
      this.cmsForm = this.cmsDefaultForm
      await this.fetchCmsSiteById(id)
    }
  },
  methods: {
    ...mapActions([
      'fetchCmsSiteById',
      'fetchEnumTypes',
      'updateCmsSite',
      'goProd',
      'enableDevelopment',
    ]),
    setData(val) {
      if (val) {
        const data = JSON.parse(JSON.stringify(val))
        this.cmsForm = {
          ...data,
          metaKeyword: data.metaKeyword.split(','),
        }
      }
    },
    onCancel() {
      this.$router.back()
    },
    onSubmit() {
      this.$refs.cmsFormRef.validate().then( async (success) => {
        if (success) {
          const data = {
            ...this.cmsForm,
            metaKeyword: this.cmsForm.metaKeyword.join(),
          }
          this.updateCmsSite({ siteId: this.siteId, data })
        } 
      })
    },
    onGoProd() {
      this.goProd(this.siteId)
    },
    async onEnableDevelopment() {
      await this.enableDevelopment(this.siteId)
      this.$router.back()
    },
  },
}
</script>
